<template>
  <div>
    <b-row>
      <b-col>
        <settings-nav></settings-nav>
      </b-col>
    </b-row>
    <b-row>
        <b-col>
            <router-view></router-view>
        </b-col>
    </b-row>
  </div>
</template>

<script>
import SettingsNav from "./components/settings-nav.vue";
export default {
  components: {
    SettingsNav,
  },
};
</script>
