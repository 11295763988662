<template>
  <div class="settings-nav w-100 d-flex">
    <b-button to="/admin/settings/"  variant="outline-primary" class="btn mx-25 px-3 my-25 my-md-0 mr-md-1 text-dark">الإعدادات العامة</b-button>
    <b-button to="/admin/settings/contact" variant="outline-primary" class=" mx-25 my-25 my-md-0 mx-md-1 px-3 text-dark">تواصل معنا</b-button>
    <b-button to="/admin/settings/clients" variant="outline-primary" class="mx-25 my-25 my-md-0  mx-md-1 px-3 text-dark">عملائنا</b-button>
    <b-button to="/admin/settings/about" variant="outline-primary" class=" mx-25 my-25 my-md-0 mx-md-1 px-3 text-dark">حولنا</b-button>
    <b-button to="/admin/settings/positions" variant="outline-primary" class=" mx-25 my-25 my-md-0 mx-md-1 px-3 text-dark">مناصب الموظفين</b-button>
  </div>
</template>

<script>
export default {};
</script>

<style lang='scss'>
@import "@/assets/scss/variables/_variables.scss";
.settings-nav {
  flex-wrap: wrap;
.btn{
    font-size: 16px;
}
.router-link-exact-active,.router-link-exact-active:focus,.router-link-exact-active:hover{
    background-color: #196DA7   !important;
    color: #fff !important;
}


}


</style>